const testRole = {
  'role': {
    'id': 'admin',
    'name': '管理员',
    'describe': '拥有所有权限',
    'status': 1,
    'creatorId': 'system',
    'createTime': 1497160610259,
    'deleted': 0,
    'permissions': [
      {
        'roleId': 'admin',
        'permissionId': 'dashboard',
        'permissionName': '仪表盘'
      },
      {
        'roleId': 'admin',
        'permissionId': 'chatbot',
        'permissionName': 'chatbot'
      },
      {
        'roleId': 'admin',
        'permissionId': 'exception',
        'permissionName': '异常页面权限'
      },
      {
        'roleId': 'admin',
        'permissionId': 'profile',
        'permissionName': '详细页权限'
      },
      {
        'roleId': 'admin',
        'permissionId': 'table',
        'permissionName': '表格权限'
      },
      {
        'roleId': 'admin',
        'permissionId': 'form',
        'permissionName': '表单权限'
      },
      {
        'roleId': 'admin',
        'permissionId': 'user',
        'permissionName': '用户管理'
      }
    ]
  }
}

export { testRole }
