/* eslint-disable */
export const printANSI = () => {
  // console.clear()
  console.log('[antd pro] created()')
  // ASCII - ANSI Shadow
  let text = `Published ${APP_VERSION}-${GIT_HASH} @ antdv.com     Build date: ${BUILD_DATE}`
  console.log(`${text}`)
  console.log('Thanks for using antd pro!')
  console.log('')
}
