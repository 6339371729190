import { _request } from '@/vai/utils'

const api = {
  List: '/bot/list',
  ListTemplates: 'bot/template/list',
  BotInfo: '/bot/get',
  Add: '/bot/add',
  GetSetting: '/bot/setting/get',
  UpdateSetting: '/bot/setting/update',
  AddWithAvatar: '/bot/addwith/avatar',
  UploadAvatar: '/bot/avatar/upload',
  Delete: '/bot/delete',
  Disable: '/bot/disable',
  Enable: '/bot/enable'
}

export function add (parameter) {
  return _request({
    url: api.Add,
    data: parameter
  })
}

export function addWithAvatar (parameter) {
  return _request({
    url: api.AddWithAvatar,
    ...parameter
  })
}

export function uploadAvatar (parameter) {
  return _request({
    url: api.UploadAvatar,
    ...parameter
  })
}

export function _delete (parameter) {
  return _request({
    url: api.Delete,
    method: 'post',
    data: parameter
  })
}

export function disable (parameter) {
  return _request({
    url: api.Disable,
    method: 'post',
    data: parameter
  })
}

export function enable (parameter) {
  return _request({
    url: api.Enable,
    method: 'post',
    data: parameter
  })
}

export function getInfo (parameter) {
  return _request({
    url: api.BotInfo,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function list (parameter) {
  return _request({
    url: api.List,
    method: 'post',
    data: parameter
  })
}

export function listTemplates (parameter) {
  return _request({
    url: api.ListTemplates,
    method: 'post',
    data: parameter
  })
}

export function updateSetting (parameter) {
  return _request({
    url: api.UpdateSetting,
    method: 'post',
    data: parameter
  })
}

export function getSetting (parameter) {
  return _request({
    url: api.GetSetting,
    method: 'post',
    data: parameter
  })
}
