import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, loginWithRememberMe, logout, getInfo } from '@/vai/api/user'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import { USER_INFO } from './constant'
import { asyncRouterMap, userRouterMap, vaiRouterMap } from '@/vai/router/router.config'
import { resetRouter, addRouter } from '@/vai/router'
import cloneDeep from 'lodash.clonedeep'

import { testRole } from './test'

storage.addPlugin(expirePlugin)

/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission (permission, route) {
  if (route.meta && route.meta.permission) {
    // console.log('hasPermission', permission)
    if (permission === undefined) {
      return false
    }
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.permission.includes(permission[i])
      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}

function filterAsyncRouter (routerMap, role) {
  const accessedRouters = routerMap.filter(route => {
    if (hasPermission(role.permissionList, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, role)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

const user = {
  namespaced: true,
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    routers: userRouterMap,
    addRouters: []
  },

  getters: {
    addRouters: state => state.addRouters
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = userRouterMap.concat(routers)
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.status && response.status.code && response.status.code !== '0000') {
            resolve(response)
            return
          }
          const token = response.header.access_token
          storage.set(ACCESS_TOKEN, token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          storage.set(USER_INFO, response.body)

          const rememberMe = response.body.user.remember_me
          storage.set('RM', rememberMe)

          commit('SET_TOKEN', token)

          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    // 登录
    LoginWithRememberMe ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginWithRememberMe(userInfo).then(response => {
          if (response.status && response.status.code && response.status.code !== '0000') {
            resolve(response)
            return
          }
          const token = response.header.access_token
          storage.set(ACCESS_TOKEN, token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
          storage.set(USER_INFO, response.body)

          const rememberMe = response.body.user.remember_me
          storage.set('RM', rememberMe)

          commit('SET_TOKEN', token)

          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        const rm = storage.get('RM')

        getInfo({ 'remember_me': rm }).then(response => {
          if (response.status && response.status.code && response.status.code !== '0000') {
            reject(response)
            return
          }

          const userInfo = response.body
          userInfo.role = testRole.role
          if (userInfo.role && userInfo.role.permissions.length > 0) {
            const role = { ...userInfo.role }
            role.permissions = userInfo.role.permissions.map(permission => {
              const per = {
                ...permission,
                actionList: (permission.actionEntitySet || []).map(item => item.action)
                }
              return per
            })
            role.permissionList = role.permissions.map(permission => { return permission.permissionId })
            // role.permissionList = ['dashboard', 'form']

            // 覆盖响应体的 role, 供下游使用
            userInfo.role = role

            commit('SET_ROLES', role)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { name: userInfo.name, welcome: welcome() })
            commit('SET_AVATAR', userInfo.avatar)

            // 根据用户权限信息生成可访问的路由表
            const routerMap = cloneDeep(asyncRouterMap)
            const accessedRouters = filterAsyncRouter(routerMap, role)
            commit('SET_ROUTERS', accessedRouters)
            commit('SET_ROUTERS', accessedRouters, { root: true })

            // 动态添加可访问路由表
            // VueRouter@3.5.0+ New API
            resetRouter() // 重置路由 防止退出重新登录或者 token 过期后页面未刷新，导致的路由重复添加
            addRouter(accessedRouters)
            addRouter(vaiRouterMap)
            // 下游
            resolve(userInfo)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          storage.remove('RM')
          resolve()
        }).catch((err) => {
          console.log('logout fail:', err)
          resolve()
        }).finally(() => {
        })
      })
    },

    // generate routes according to user permission information
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const { role } = data
        const routerMap = cloneDeep(asyncRouterMap)
        const accessedRouters = filterAsyncRouter(routerMap, role)
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    }

  }
}

export default user
