
import * as botApi from '@/vai/api/bot'

const bot = {
  namespaced: true,
  state: {
    bot: {},
    bots: []
  },

  getters: {
    bot: state => state.bot,
    bots: state => state.bot
  },

  mutations: {
    SET_BOT: (state, bot) => {
      state.bot = bot
    },
    SET_BOTS: (state, bots) => {
      state.bots = bots
    }
  },

  actions: {
    // add a bot
    Add ({ commit, state }, botInfo) {
      return new Promise((resolve, reject) => {
        botApi.add(botInfo).then(response => {
          const bot = response.body
          const bots = [...state.bots, bot]
          commit('SET_BOTS', bots)
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    // add a bot
    Delete ({ commit, state }, botInfo) {
      return new Promise((resolve, reject) => {
        botApi._delete(botInfo).then(response => {
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    // add a bot
    Disable ({ commit, state }, botInfo) {
      return new Promise((resolve, reject) => {
        botApi.disable(botInfo).then(response => {
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    // add a bot
    Enable ({ commit, state }, botInfo) {
      return new Promise((resolve, reject) => {
        botApi.enable(botInfo).then(response => {
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    // 获取bot信息
    GetInfo ({ commit }, id) {
      return new Promise((resolve, reject) => {
        botApi.getInfo({ 'id': id }).then(response => {
          const bot = response.body
          commit('SET_BOT', bot)
          resolve(bot)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // list
    List ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        botApi.list(params).then((response) => {
          const bots = response.body.result
          commit('SET_BOTS', bots)
          resolve(response.body)
        }).catch((err) => {
          console.log('Error:', err)
          reject(err)
        }).finally(() => {
        })
      })
    }

  }
}

export default bot
