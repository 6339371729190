
import { userRouterMap } from './router.config'
import router, { resetRouter as recreateRouter } from '@/router'

userRouterMap.map(item => router.addRoute(item))

export function resetRouter () {
  recreateRouter()
  userRouterMap.map(item => router.addRoute(item))
}

export function addRouter (addRouters) {
  addRouters.map(item => router.addRoute(item))
}
