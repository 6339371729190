<template>
  <vai-layout>
    <router-view />
  </vai-layout>
</template>

<script>

import VaiLayout from './VaiLayout'

export default {
  components: {
    VaiLayout
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style lang="less" scoped>

</style>
