import './lang'
import storage from 'store'
import expirePlugin from 'store/plugins/expire'

import './router/index'
import vaiStore from './store'
import './permission' // permission control

// import * as gbpApi from './api/gbp'
// import Vue from 'vue'
// import GAuth from 'vue-google-oauth2'

// gbpApi.getGoogleClientId().then(result => {
//   // https://github.com/guruahn/vue-google-oauth2
//   // const clientId = document.querySelector('meta[name="google-signin-client_id"]').content
//   const clientId = result.body.googleClientId
//   const gauthOption = {
//     clientId: clientId,
//     scope: ['https://www.googleapis.com/auth/business.manage', 'https://www.googleapis.com/auth/plus.business.manage'].join(' '),
//     prompt: 'consent',
//     fetch_basic_profile: 'true'
//   }
//   Vue.use(GAuth, gauthOption)
// }).catch((err) => {
//   console.log(err)
// })

storage.addPlugin(expirePlugin)

export default vaiStore
