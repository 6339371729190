export default {
  'user.login.email.placeholder': 'Email: ',
  'user.getinfo.error': 'Get user information failed. Please try again!',
  // below is the default value
  'user.login.userName': 'userName',
  'user.login.password': 'password',
  'user.login.username.placeholder': 'Account: ',
  'user.login.password.placeholder': 'password: ',
  'user.login.message-invalid-credentials': 'Invalid username or password',
  'user.login.message-invalid-verification-code': 'Invalid verification code',
  'user.login.tab-login-credentials': 'Credentials',
  'user.login.tab-login-mobile': 'Mobile number',
  'user.login.mobile.placeholder': 'Mobile number',
  'user.login.mobile.verification-code.placeholder': 'Verification code',
  'user.login.remember-me': 'Remember me',
  'user.login.forgot-password': 'Forgot your password?',
  'user.login.sign-in-with': 'Sign in with',
  'user.login.signup': 'Sign up',
  'user.login.login': 'Login',
  'user.register.register': 'Register',
  'user.register.email.placeholder': 'Email',
  'user.register.password.placeholder': 'Password ',
  'user.register.password.popover-message': 'At least eight characters, at least one letter and one number. Please do not use passwords that are easy to guess. ',
  'user.register.confirm-password.placeholder': 'Confirm password',
  'user.register.get-verification-code': 'Get code',
  'user.register.sign-in': 'Already have an account?',
  'user.register.sms.sending': 'Verification code is sending out.',
  'user.register-result.msg': 'Account：registered at {email}',
  'user.register-result.activation-email':
    'The activation email has been sent to your email address and is valid for 24 hours. Please log in to the email in time and click on the link in the email to activate the account.',
  'user.register-result.back-home': 'Back to home',
  'user.register-result.view-mailbox': 'View mailbox',
  'user.email.required': 'Please enter your email!',
  'user.email.wrong-format': 'The email address is in the wrong format!',
  'user.userName.required': 'Please enter account name or email address',
  'user.password.reset': 'Reset Password',
  'user.password.required': 'Please enter your password!',
  'user.password.twice.msg': 'The passwords entered twice do not match!',
  'user.password.strength.msg': 'The password is not strong enough',
  'user.password.strength.strong': 'Strength: strong',
  'user.password.strength.medium': 'Strength: medium',
  'user.password.strength.low': 'Strength: low',
  'user.password.strength.short': 'Strength: too short',
  'user.password.not.qualified': 'At least eight characters, at least one letter and one number. ',
  'user.confirm-password.required': 'Please confirm your password!',
  'user.phone-number.required': 'Please enter your phone number!',
  'user.phone-number.wrong-format': 'Please enter a valid phone number',
  'user.verification-code.required': 'Please enter the verification code!'
}
