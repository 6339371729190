// eslint-disable-next-line
import { UserLayout, BasicLayout, VaiBasicLayout, LocationLayout, BlankLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/location/jump',
    children: [
      // dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: RouteView,
        meta: { title: 'menu.dashboard', keepAlive: true, icon: bxAnaalyse, permission: ['dashboard'] },
        hidden: true,
        children: [
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/dashboard/Analysis'),
            meta: { title: 'menu.dashboard.analysis', keepAlive: false, permission: ['dashboard'] }
          },
          // 外部链接
          // {
          //   path: 'https://www.baidu.com/',
          //   name: 'Monitor',
          //   meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          // },
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: 'menu.dashboard.workplace', keepAlive: true, permission: ['dashboard'] }
          }
        ]
      },
      // dashboard
      {
        path: '/location/:location_id/summary',
        name: 'summary',
        component: () => import('@/vai/views/summary/LocationSummary'),
        meta: { title: 'Location Summary', keepAlive: true, icon: bxAnaalyse, permission: ['dashboard'] }
      },
      {
        path: '/location/:location_id/manager',
        name: 'manager',
        redirect: '/location/:location_id/info',
        component: () => import('@/vai/views/manager/Index'),
        meta: { title: 'Location Manager', keepAlive: true, icon: 'home', permission: ['dashboard'] },
        hideChildrenInMenu: true,
        children: [
          {
            path: '/location/:location_id/info',
            name: 'LocationInfo',
            component: () => import('@/vai/views/manager/LocationInfo'),
            hidden: true,
            meta: { title: 'Location Information', keepAlive: false, hidden: true, hiddenHeaderContent: true, permission: ['form'] }
          }
        ]
      },
      // gbp post
      {
        path: '/location/:location_id/gbp/post',
        name: 'GBP Post',
        redirect: '/gbp/location/post/:location_id',
        component: () => import('@/vai/views/gbp/Index'),
        meta: { title: 'GBP Post', keepAlive: true, icon: 'form', permission: ['chatbot'] },
        hideChildrenInMenu: true,
        children: [
          {
            path: '/gbp/location/post/:location_id',
            name: 'Locations',
            component: () => import('@/vai/views/gbp/post/PostPage'),
            meta: { title: 'GBP Post', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          }
        ]
      },
      // gbp reputation
      {
        path: '/location/:location_id/reputation',
        name: 'GBP',
        redirect: '/location/:location_id/reputation/overview',
        component: () => import('@/vai/views/reputation/Index'),
        meta: { title: 'Reputation Manager', keepAlive: true, icon: 'sketch', permission: ['chatbot'] },
        hideChildrenInMenu: true,
        children: [
          {
            path: '/location/:location_id/reputation/overview',
            name: 'Reputation Overview',
            component: () => import('@/vai/views/reputation/overview/OverviewPage'),
            meta: { title: 'Overview', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/reputation/review',
            name: 'Reputation Review',
            component: () => import('@/vai/views/reputation/review/ReviewPage'),
            meta: { title: 'Reputation Reviews', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/reputation/request',
            name: 'Reputation Request',
            component: () => import('@/vai/views/reputation/request/RequestPage'),
            meta: { title: 'Reputation Review Requests', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/reputation/funnel',
            name: 'Reputation Request',
            component: () => import('@/vai/views/reputation/funnel/FunnelPage'),
            meta: { title: 'Reputation Funnels', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/reputation/showcase',
            name: 'Reputation Request',
            component: () => import('@/vai/views/reputation/showcase/ShowcasePage'),
            meta: { title: 'Showcase', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/reputation/setting',
            name: 'Reputation Request',
            component: () => import('@/vai/views/reputation/setting/SettingPage'),
            meta: { title: 'Reputation Setting', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/gbp/location/list/:location_id',
            name: 'Locations',
            component: () => import('@/vai/views/gbp/Locations'),
            meta: { title: 'GBP Locations', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/gbp/reputation/info',
            name: 'LocationInfo',
            component: () => import('@/vai/views/gbp/review/ReviewPage'),
            hidden: true,
            meta: { title: 'Location Information', keepAlive: false, hidden: true, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/info/locations/:location_id',
            name: 'LocationInfo',
            component: () => import('@/vai/views/gbp/LocationDetail'),
            hidden: true,
            meta: { title: 'Location Information', keepAlive: false, hidden: true, hiddenHeaderContent: true, permission: ['form'] }
          }
        ]
      },
      // Social Media
      {
        path: '/location/social/:location_id',
        name: 'social',
        component: () => import('@/views/dashboard/Analysis'),
        meta: { title: 'Social Media', keepAlive: true, icon: 'fork', permission: ['dashboard'] }
      },
      // chatbot
      {
        path: '/location/:location_id/bot',
        name: 'Chatbot',
        redirect: '/location/:location_id/bot/list',
        component: () => import('@/vai/views/chatbot/Index'),
        meta: { title: 'menu.chatbot', keepAlive: true, icon: 'android', permission: ['chatbot'] },
        hideChildrenInMenu: true,
        children: [
          {
            path: '/location/:location_id/bot/list',
            name: 'LocationBot',
            component: () => import('@/vai/views/chatbot/LocationBot'),
            meta: { title: 'menu.chatbot.bots', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/bot/list_',
            name: 'Bots',
            component: () => import('@/vai/views/chatbot/Bots'),
            meta: { title: 'menu.chatbot.bots', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          },
          {
            path: '/location/:location_id/bot/:bot_id/info',
            name: 'BotInfo',
            component: () => import('@/vai/views/chatbot/BotInfo'),
            meta: { title: 'menu.chatbot.botinfo', keepAlive: false, hiddenHeaderContent: true, permission: ['form'] }
          }
        ]
      },
      // Website
      {
        path: '/location/website/:location_id',
        name: 'website',
        component: () => import('@/views/dashboard/Analysis'),
        meta: { title: 'Website', keepAlive: true, icon: 'global', permission: ['dashboard'] }
      },
      // Contacts
      {
        path: '/location/:location_id/contact',
        name: 'contact',
        component: () => import('@/vai/views/contact/Index'),
        meta: { title: 'Contacts', keepAlive: true, icon: 'contacts', permission: ['dashboard'] }
      },
      // Compaign
      {
        path: '/location/:location_id/compaign',
        name: 'compaign',
        component: () => import('@/views/dashboard/Analysis'),
        meta: { title: 'Compaign', keepAlive: true, icon: 'message', permission: ['dashboard'] }
      },
      // Compaign
      {
        path: '/location/:location_id/template',
        name: 'template',
        component: () => import('@/views/dashboard/Analysis'),
        meta: { title: 'Template', keepAlive: true, icon: 'container', permission: ['dashboard'] }
      },
      // Automation
      {
        path: '/location/:location_id/automation',
        name: 'automation',
        component: () => import('@/views/dashboard/Analysis'),
        meta: { title: 'Automation', keepAlive: true, icon: 'sync', permission: ['dashboard'] }
      },
      // Exception
      {
        path: '/exception',
        name: 'exception',
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
        hidden: true,
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
            meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
            meta: { title: 'menu.exception.not-find', permission: ['exception'] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
            meta: { title: 'menu.exception.server-error', permission: ['exception'] }
          }
        ]
      }
      /*
      // forms
      {
        path: '/form',
        redirect: '/form/base-form',
        component: RouteView,
        meta: { title: 'menu.form', icon: 'form', permission: ['form'] },
        children: [
          {
            path: '/form/base-form',
            name: 'BaseForm',
            component: () => import('@/views/form/basicForm'),
            meta: { title: 'menu.form.basic-form', keepAlive: true, permission: ['form'] }
          },
          {
            path: '/form/step-form',
            name: 'StepForm',
            component: () => import('@/views/form/stepForm/StepForm'),
            meta: { title: 'menu.form.step-form', keepAlive: true, permission: ['form'] }
          },
          {
            path: '/form/advanced-form',
            name: 'AdvanceForm',
            component: () => import('@/views/form/advancedForm/AdvancedForm'),
            meta: { title: 'menu.form.advanced-form', keepAlive: true, permission: ['form'] }
          }
        ]
      },
      // list
      {
        path: '/list',
        name: 'list',
        component: RouteView,
        redirect: '/list/table-list',
        meta: { title: 'menu.list', icon: 'table', permission: ['table'] },
        children: [
          {
            path: '/list/table-list/:pageNo([1-9]\\d*)?',
            name: 'TableListWrapper',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import('@/views/list/TableList'),
            meta: { title: 'menu.list.table-list', keepAlive: true, permission: ['table'] }
          },
          {
            path: '/list/basic-list',
            name: 'BasicList',
            component: () => import('@/views/list/BasicList'),
            meta: { title: 'menu.list.basic-list', keepAlive: true, permission: ['table'] }
          },
          {
            path: '/list/card',
            name: 'CardList',
            component: () => import('@/views/list/CardList'),
            meta: { title: 'menu.list.card-list', keepAlive: true, permission: ['table'] }
          },
          {
            path: '/list/search',
            name: 'SearchList',
            component: () => import('@/views/list/search/SearchLayout'),
            redirect: '/list/search/article',
            meta: { title: 'menu.list.search-list', keepAlive: true, permission: ['table'] },
            children: [
              {
                path: '/list/search/article',
                name: 'SearchArticles',
                component: () => import('@/views/list/search/Article'),
                meta: { title: 'menu.list.search-list.articles', permission: ['table'] }
              },
              {
                path: '/list/search/project',
                name: 'SearchProjects',
                component: () => import('@/views/list/search/Projects'),
                meta: { title: 'menu.list.search-list.projects', permission: ['table'] }
              },
              {
                path: '/list/search/application',
                name: 'SearchApplications',
                component: () => import('@/views/list/search/Applications'),
                meta: { title: 'menu.list.search-list.applications', permission: ['table'] }
              }
            ]
          }
        ]
      },

      // profile
      {
        path: '/profile',
        name: 'profile',
        component: RouteView,
        redirect: '/profile/basic',
        meta: { title: 'menu.profile', icon: 'profile', permission: ['profile'] },
        children: [
          {
            path: '/profile/basic',
            name: 'ProfileBasic',
            component: () => import('@/views/profile/basic'),
            meta: { title: 'menu.profile.basic', permission: ['profile'] }
          },
          {
            path: '/profile/advanced',
            name: 'ProfileAdvanced',
            component: () => import('@/views/profile/advanced/Advanced'),
            meta: { title: 'menu.profile.advanced', permission: ['profile'] }
          }
        ]
      },

      // result
      {
        path: '/result',
        name: 'result',
        component: RouteView,
        redirect: '/result/success',
        meta: { title: 'menu.result', icon: 'check-circle-o', permission: ['result'] },
        children: [
          {
            path: '/result/success',
            name: 'ResultSuccess',
            component: () => import('@/views/result/Success'),
            meta: { title: 'menu.result.success', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
          },
          {
            path: '/result/fail',
            name: 'ResultFail',
            component: () => import('@/views/result/Error'),
            meta: { title: 'menu.result.fail', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
          }
        ]
      },
      // account
      {
        path: '/account',
        component: RouteView,
        redirect: '/account/center',
        name: 'account',
        meta: { title: 'menu.account', icon: 'user', keepAlive: true, permission: ['user'] },
        children: [
          {
            path: '/account/center',
            name: 'center',
            component: () => import('@/views/account/center'),
            meta: { title: 'menu.account.center', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/account/billing',
            name: 'center',
            component: () => import('@/vai/views/settings/billing/Index'),
            meta: { title: 'menu.account.center', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/account/settings',
            name: 'settings',
            component: () => import('@/views/account/settings/Index'),
            meta: { title: 'menu.account.settings', hideHeader: true, permission: ['user'] },
            redirect: '/account/settings/basic',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/account/settings/basic',
                name: 'BasicSettings',
                component: () => import('@/views/account/settings/BasicSetting'),
                meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['user'] }
              },
              {
                path: '/account/settings/security',
                name: 'SecuritySettings',
                component: () => import('@/views/account/settings/Security'),
                meta: {
                  title: 'account.settings.menuMap.security',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              },
              {
                path: '/account/settings/custom',
                name: 'CustomSettings',
                component: () => import('@/views/account/settings/Custom'),
                meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/binding',
                name: 'BindingSettings',
                component: () => import('@/views/account/settings/Binding'),
                meta: { title: 'account.settings.menuMap.binding', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/notification',
                name: 'NotificationSettings',
                component: () => import('@/views/account/settings/Notification'),
                meta: {
                  title: 'account.settings.menuMap.notification',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              }
            ]
          }
        ]
      }
      */
      // other
      /*
      {
        path: '/other',
        name: 'otherPage',
        component: PageView,
        meta: { title: '其他组件', icon: 'slack', permission: [ 'dashboard' ] },
        redirect: '/other/icon-selector',
        children: [
          {
            path: '/other/icon-selector',
            name: 'TestIconSelect',
            component: () => import('@/views/other/IconSelectorView'),
            meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: [ 'dashboard' ] }
          },
          {
            path: '/other/list',
            component: RouteView,
            meta: { title: '业务布局', icon: 'layout', permission: [ 'support' ] },
            redirect: '/other/list/tree-list',
            children: [
              {
                path: '/other/list/tree-list',
                name: 'TreeList',
                component: () => import('@/views/other/TreeList'),
                meta: { title: '树目录表格', keepAlive: true }
              },
              {
                path: '/other/list/edit-table',
                name: 'EditList',
                component: () => import('@/views/other/TableInnerEditList'),
                meta: { title: '内联编辑表格', keepAlive: true }
              },
              {
                path: '/other/list/user-list',
                name: 'UserList',
                component: () => import('@/views/other/UserList'),
                meta: { title: '用户列表', keepAlive: true }
              },
              {
                path: '/other/list/role-list',
                name: 'RoleList',
                component: () => import('@/views/other/RoleList'),
                meta: { title: '角色列表', keepAlive: true }
              },
              {
                path: '/other/list/system-role',
                name: 'SystemRole',
                component: () => import('@/views/role/RoleList'),
                meta: { title: '角色列表2', keepAlive: true }
              },
              {
                path: '/other/list/permission-list',
                name: 'PermissionList',
                component: () => import('@/views/other/PermissionList'),
                meta: { title: '权限列表', keepAlive: true }
              }
            ]
          }
        ]
      }
      */
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const userRouterMap = [
  {
    path: '/vuser',
    component: UserLayout,
    redirect: '/vuser/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/Register')
      },
      {
        path: '/vuser/register-result',
        name: 'registerResult2',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/RegisterResult')
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/ResetPassword')
      },
      {
        path: 'reset-password-result',
        name: 'resetPasswordResult',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/ResetPasswordResult')
      }
    ]
  }

]

export const vaiRouterMap = [
  {
    path: '/location',
    component: VaiBasicLayout,
    redirect: '/location/list',
    meta: { title: 'Home', keepAlive: true, icon: 'sketch', permission: ['chatbot'] },
    hideChildrenInMenu: true,
    children: [
      {
        path: '/location/jump',
        name: 'Locations',
        component: () => import('@/vai/views/location/LocationJump'),
        meta: { title: 'Locations', keepAlive: true, icon: 'form', permission: ['chatbot'] }
      },
      {
        path: '/location/list',
        name: 'Locations',
        component: () => import('@/vai/views/location/Locations'),
        meta: { title: 'Locations', keepAlive: true, icon: 'form', permission: ['chatbot'] }
      },
      // settings
      {
        path: '/vaccount/settings',
        name: 'settings',
        component: () => import('@/vai/views/settings/Index'),
        meta: { title: 'menu.account.settings', icon: 'user', hidden: true, hideHeader: true, permission: ['user'] },
        redirect: '/vaccount/settings/account/personal',
        hideChildrenInMenu: true,
        hidden: true,
        children: [
          {
            path: '/vaccount/settings/account/personal',
            name: 'PersonalInfo',
            component: () => import('@/vai/views/settings/account/PersonalInfo'),
            meta: { title: 'Personal Details', hidden: true, permission: ['user'] }
          },
          {
            path: '/vaccount/settings/account/company',
            name: 'CompanyInfo',
            component: () => import('@/vai/views/settings/account/CompanyInfo'),
            meta: { title: 'Company Details', hidden: true, permission: ['user'] }
          },
          {
            path: '/vaccount/settings/account/agency',
            name: 'AgencyInfo',
            component: () => import('@/vai/views/settings/account/AgencyInfo'),
            meta: { title: 'Agency Info', hidden: true, permission: ['user'] }
          },
          {
            path: '/vaccount/settings/account/team',
            name: 'TeamInfo',
            component: () => import('@/vai/views/settings/account/TeamInfo'),
            meta: { title: 'User Details', hidden: true, permission: ['user'] }
          },
          {
            path: '/vaccount/settings/billing/planbilling',
            name: 'PlanBilling',
            component: () => import('@/vai/views/settings/billing/Index'),
            meta: { title: 'Billing Details', hidden: true, permission: ['user'] }
          },
          {
            path: '/vaccount/settings/billing/paymentsetting',
            name: 'PaymentSetting',
            component: () => import('@/vai/views/settings/billing/PaymentSetting'),
            meta: { title: 'Payment Setting', hidden: true, permission: ['user'] }
          },
          {
            path: '/vaccount/settings/billing',
            name: 'BillingInformation',
            component: () => import('@/vai/views/settings/billing/Index'),
            meta: { title: 'Billing Information', hidden: true, keepAlive: false, permission: ['user'] }
          }
        ]
      },
      {
        path: '/vai/gbp/location/info/locations/:id',
        name: 'LocationInfo',
        component: () => import('@/vai/views/gbp/LocationDetail'),
        hidden: true,
        meta: { title: 'Location Information', keepAlive: false, hidden: true, hiddenHeaderContent: true, permission: ['form'] }
      }
    ]
  },
  {
    path: '/vai',
    component: VaiBasicLayout,
    meta: { title: 'Home' },
    redirect: '/vai/resetpassword',
    hidden: true,
    children: [
      {
        path: 'resetpassword',
        name: 'resetpassword',
        component: () => import('@/vai/views/user/ResetPassword')
      }
    ]
  }
]
