import { _request } from '@/vai/utils'

const api = {
  Login: '/user/login',
  LoginWithRememberMe: '/user/login/rememberme',
  Logout: '/user/logout',
  ForgePassword: '/user/forge-password',
  ResetPassword: '/user/password/reset',
  ChangePassword: '/user/changepassword',
  Register: '/user/register',
  twoStepCode: '/user/2step-code',
  SendSmsCode: '/user/send/sms/code',
  SendEmailCode: '/user/send/email/code',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info',
  UserMenu: '/user/nav',
  Update: '/user/update',

  ListMembers: '/user/team/members',
  AddMember: '/user/team/member/add',
  DeleteMember: '/user/team/member/delete',

  GetTenant: '/tenant/get',
  UpdateTenant: '/tenant/update'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return _request({
    url: api.Login,
    method: 'post',
    data: parameter
  })
}

export function loginWithRememberMe (parameter) {
  return _request({
    url: api.LoginWithRememberMe,
    method: 'post',
    data: parameter
  })
}

export function register (parameter) {
  return _request({
    url: api.Register,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  return _request({
    url: api.Update,
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return _request({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo (parameter) {
  return _request({
    url: api.UserInfo,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav () {
  return _request({
    url: api.UserMenu,
    method: 'get'
  })
}

export function logout () {
  return _request({
    url: api.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return _request({
    url: api.twoStepCode,
    method: 'post',
    data: parameter
  })
}

export function sendSmsCode (parameter) {
  return _request({
    url: api.SendSmsCode,
    data: parameter
  })
}

export function sendEmailCode (parameter) {
  return _request({
    url: api.SendEmailCode,
    data: parameter
  })
}

export function resetPassword (parameter) {
  return _request({
    url: api.ResetPassword,
    method: 'post',
    data: parameter
  })
}

export function changePassword (parameter) {
  return _request({
    url: api.ChangePassword,
    method: 'post',
    data: parameter
  })
}

export function listMembers (parameter) {
  return _request({
    url: api.ListMembers,
    method: 'post',
    data: parameter
  })
}

export function addMember (parameter) {
  return _request({
    url: api.AddMember,
    method: 'post',
    data: parameter
  })
}

export function deleteMember (parameter) {
  return _request({
    url: api.DeleteMember,
    method: 'post',
    data: parameter
  })
}

export function getTenant (parameter) {
  return _request({
    url: api.GetTenant,
    method: 'post',
    data: parameter
  })
}

export function updateTenant (parameter) {
  return _request({
    url: api.UpdateTenant,
    method: 'post',
    data: parameter
  })
}
