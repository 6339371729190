import enUS from '@/locales/lang/en-US'
import user from './en-US/user'
import app from './en-US/app'
import menu from './en-US/menu'

Object.keys(app).forEach((key) => {
  enUS[key] = app[key]
})
Object.keys(user).forEach((key) => {
  enUS[key] = user[key]
})
Object.keys(menu).forEach((key) => {
  enUS[key] = menu[key]
})
